import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { heading32, headline16 } from '@pretto/bricks/core/typography'
import YoutubeVideo from '@pretto/bricks/website/utility/ContentBlock/components/YoutubeVideo'

import { Button as ButtonComponent } from '@pretto/zen/atoms/buttons/Button/Button'

import styled, { css } from 'styled-components'

type VideoWrapperProps = {
  $isOnlyVideo: boolean
}

export const Grid = styled.div`
  ${grid()};
  align-items: flex-start;
`

export const VideoWrapper = styled.div<VideoWrapperProps>`
  ${column([2, 4])};
  position: relative;
  border-radius: ${g(1)};
  overflow: hidden;
  grid-row: 2;

  ${({ $isOnlyVideo }) =>
    !$isOnlyVideo &&
    css`
      margin-top: ${g(2)};
    `};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ $isOnlyVideo }) => ($isOnlyVideo ? column([4, 8]) : column([3, 5]))};
    grid-row: 1;
    margin-top: 0px;
  }
`

export const Video = styled(YoutubeVideo).attrs({
  options: {
    aspectRatio: '1:1',
    crop: 'fill',
  },
  sizes: `(min-width: ${breakpoints.laptop}) 30vw, (min-width: ${breakpoints.tablet}) ${100 / 3}vw, 100vw`,
  srcSetBreakpoints: [
    numeralBreakpoints.mobileS,
    numeralBreakpoints.mobileM,
    numeralBreakpoints.mobileL,
    numeralBreakpoints.tablet,
    numeralBreakpoints.laptop,
  ],
})`
  display: block;
  height: 100%;
  width: 100%;
`

export const ContentWrapper = styled.div`
  ${column([2, 4])};
  grid-row: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([8, 5])};
  }
`

export const Suptitle = styled.div`
  ${headline16};
  margin-bottom: ${g(2)};
`

export const Title = styled.h2`
  ${heading32};
  margin-bottom: ${g(3)};
`

export const Button = styled(ButtonComponent).attrs({ isSecondary: true })`
  margin-top: ${g(4)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`
